import { useContext } from 'react';
import { AppFeaturesContext } from '@/providers/AppFeatureProvider';

/**
 * Hook which returns all AppFeatures data (for client components usage)
 */
export function useAppFeatures(): Record<string, boolean> {
  const context = useContext(AppFeaturesContext);
  if (!context) {
    throw new Error(`useAppFeatures must be used within a AppFeaturesContextProvider`);
  }
  return context;
}
