'use client';

import useShoppingCart from '@/hooks/useShoppingCart';
import type { Locale } from '@/i18n';
import ShoppingCartContainer from '@/components/legacy/header/cart/ShoppingCartContainer';
import RequestedVenueList from '@/components/legacy/header/cart/RequestedVenuesList';
import ShoppingCartHeader from '@/components/legacy/header/cart/ShoppingCartHeader';
import ShoppingCartFooter from '@/components/legacy/header/cart/footer/ShoppingCartFooter';
import { usePathname, useSearchParams } from 'next/navigation';
import VenuesInRfpList from '@/components/legacy/header/cart/VenuesInRfpList';
import { useMemo } from 'react';
import { getRFPListStrings } from '@/utils/shoppingCartHelper';
import { Categories } from '@/constants/csnSearchStatsConstants';

type ShoppingCartProps = {
  isLoggedIn: boolean;
  strings: Record<string, string>;
  locale: Locale;
  cventAppDomain: string;
  isPhxEvtContainerExpOn: boolean;
  isV2ProfileEnabledForAccount: boolean;
};

export default function ShoppingCart({
  isLoggedIn,
  strings,
  locale,
  cventAppDomain,
  isPhxEvtContainerExpOn,
  isV2ProfileEnabledForAccount
}: ShoppingCartProps): JSX.Element {
  const { items, rfpVenueList, isRfpListCollapsed } = useShoppingCart();
  const searchParams = useSearchParams();
  const pathName = usePathname();
  const rfpId = searchParams?.get('rfpId');
  const testID = 'shopping-cart';
  const venueListMaxHeight = useMemo(() => {
    if (rfpVenueList?.length > 0 && !isRfpListCollapsed) {
      return 'max-h-[100px] md:max-h-[21vh]';
    }
    return 'max-h-[230px] md:max-h-[46vh]';
  }, [rfpVenueList, isRfpListCollapsed]);

  // hub page in url
  const segments = pathName.split('/');
  const hubCategory = segments.find(e => Array.from(Categories.values()).filter(f => f.path === e).length > 0);

  const rfpListStrings: Record<string, string> = useMemo(() => getRFPListStrings(strings), [strings]);
  return (
    <ShoppingCartContainer locale={locale} strings={strings} isLoggedIn={isLoggedIn} testID={testID}>
      <>
        <ShoppingCartHeader isLoggedIn={isLoggedIn} strings={strings} locale={locale} />
        <div className={`${venueListMaxHeight} animate-[flyoutEaseIn_250ms_ease-in] overflow-y-auto px-5 py-2.5`}>
          <RequestedVenueList
            isLoggedIn={isLoggedIn}
            strings={strings}
            dataCventId={testID}
            locale={locale}
            hubCategory={hubCategory}
            isV2ProfileEnabledForAccount={isV2ProfileEnabledForAccount}
          />
        </div>
        {rfpId !== null && rfpVenueList?.length > 0 && (
          <VenuesInRfpList
            isLoggedIn={isLoggedIn}
            strings={rfpListStrings}
            locale={locale}
            rfpId={rfpId}
            cventAppDomain={cventAppDomain}
            testID={`${testID}-rfp-list`}
            hubCategory={hubCategory}
            isV2ProfileEnabledForAccount={isV2ProfileEnabledForAccount}
          />
        )}
        <ShoppingCartFooter
          isLoggedIn={isLoggedIn}
          cartItems={items}
          strings={strings}
          locale={locale}
          testID={testID}
          isPhxEvtContainerExpOn={isPhxEvtContainerExpOn}
        />
      </>
    </ShoppingCartContainer>
  );
}
